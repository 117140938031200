import $ from 'jquery';
import Structure from '../modules/structure';

$.fn.colorPickerScript = function() {

    const jqAllAffectedContainers = this;

    const buildColors = function(structure) {
        const colorsData = [];
        structure.colors.each(function() {
            const color = $(this);
            const link = new Structure('colorPickerScript', color, {
                link: "a"
            }).structure.link;
            const image = link.attr('href');
            const name = link.attr('title');
            colorsData.push({
                color: color,
                link: link,
                image: image,
                name: name
            });
       });
       return colorsData;
    }

    jqAllAffectedContainers.each(function() {
       const container = $(this);
       let currentShownImage;
       const structure = new Structure('colorPickerScript', container, {
            firstImage: ".color-preview-image .image img.first",
            secondImage: ".color-preview-image .image img.second",
            nameContainer: ".color-preview-image .text .name",
            colors: {
                selector: ".colors .color",
                many: true
            }
       }).structure;

       const colorsData = buildColors(structure);

       // setup
       const firstColor = $(colorsData).get(0);
       structure.firstImage.addClass('current');
       structure.secondImage.removeClass('current');
       structure.colors.removeClass('current');
       currentShownImage = 'first';
       
       structure.firstImage.attr('src', firstColor.image);
       structure.secondImage.attr('src', '');
       firstColor.color.addClass('current');

       // events
       $(colorsData).each(function() {
            const color = this;
            color.link.on('click', function(e) {
                e.preventDefault();
                structure.colors.removeClass('current');
                color.color.addClass('current');
                structure.nameContainer.text(color.name);

                console.log('currentShownImage', currentShownImage);

                if (currentShownImage == 'first') {
                    console.log('first');
                    currentShownImage = 'second';
                    structure.secondImage.attr('src', color.image);
                    structure.secondImage.ready(function() {
                        structure.firstImage.removeClass('current');
                        structure.secondImage.addClass('current');
                    }); 
                    return;
                }

                if (currentShownImage == 'second') {
                    console.log('second');
                    currentShownImage = 'first';
                    structure.firstImage.attr('src', color.image);
                    structure.firstImage.ready(function() {
                        structure.secondImage.removeClass('current');
                        structure.firstImage.addClass('current');
                    }); 
                    return;
                }

                return;
                
            })
       });
       
    });

    return jqAllAffectedContainers;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.color-picker-js').colorPickerScript();
});
