import $ from 'jquery';
import Structure from '../modules/structure';

$.fn.compositionScript = function() {

    const jqAllAffectedContainers = this;

    const getStepsData = function(structure) {
        const stepsData = [];
        structure.steps.each(function(index) {
            const stepContainer = $(this);
            const count = index + 1;
            const link = new Structure('compositionScript', stepContainer, {
                link: 'a'
            }).structure.link;
            const structureContainsId = link.attr('href');
            const structureContains = new Structure('compositionScript', structure.contains, {
                stepContains: structureContainsId
            }).structure.stepContains;
            const heightVal = structureContains.height();
            stepsData.push({
                'step': stepContainer,
                'link': link,
                'count': count,
                'height': heightVal,
                'contains': structureContains
            })
        })
        return stepsData;
    }

    const makeCurrent = function(currentStepData, stepsData, structure) {
        $(stepsData).each(function(index) {
            const localStepsData = this;
            if (localStepsData.count <= currentStepData.count) {
                localStepsData.step.addClass('current');
            } else {
                localStepsData.step.removeClass('current');
            }
        });
        structure.allContains.removeClass('current');
        structure.holder.height(currentStepData.height);
        currentStepData.contains.addClass('current');
        if (currentStepData.count > 1) {
            structure.prev.removeClass('disabled');
        } else {
            structure.prev.addClass('disabled');
        }

        if (currentStepData.count < stepsData.length) {
            structure.next.removeClass('disabled');
        } else {
            structure.next.addClass('disabled');
        }

        return currentStepData.count;
    }

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        let currentStep = 1;
        const structure = new Structure('compositionScript', container, {
            steps: {
                selector: '.steps-controlls .steps-positions .step',
                many: true
            },
            holder: '.steps-contains-holder',
            prev: '.steps-controlls .prev',
            next: '.steps-controlls .next',
            contains: '.steps-contains',
            allContains: {
                selector: '.steps-contains .step-contains',
                many: true
            }
        }).structure;
        const stepsData = getStepsData(structure);

        $(stepsData).each(function(index) {
            const stepData = this;
            const count = index + 1;
            if (count == currentStep) {
                makeCurrent(stepData, stepsData, structure);
            }
            stepData.link.on('click', function(e) {
                e.preventDefault();
                currentStep = makeCurrent(stepData, stepsData, structure);
            });
        });

        structure.prev.on('click', function(e) {
            e.preventDefault();
            currentStep = currentStep-1;
            $(stepsData).each(function(index) {
                const stepData = this;
                const count = index + 1;
                if (count == currentStep) {
                    makeCurrent(stepData, stepsData, structure);
                }
            })
        });

        structure.next.on('click', function(e) {
            e.preventDefault();
            currentStep = currentStep+1;
            $(stepsData).each(function(index) {
                const stepData = this;
                const count = index + 1;
                if (count == currentStep) {
                    makeCurrent(stepData, stepsData, structure);
                }
            })
        });

    });

    return this;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.composition-js').compositionScript();
});
